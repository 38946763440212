import { z } from "zod";

export const ZMenuPhoto = z.object({
	normal: z.string(),
	thumbnail: z.string(),
});

export const ZMenu = z.object({
	id: z.string(),
	name: z.string(),
	lastModified: z.coerce.date(),
	lastModifiedBy: z.nullable(z.string()),
	description: z.string(),
	images: z.nullable(ZMenuPhoto),
	tags: z.array(z.string()),
	recipes: z.array(z.string()),
});

export type Menu = z.infer<typeof ZMenu>;

export const ZMenuList = z.object({
	menus: z.array(ZMenu),
});

export type MenuList = z.infer<typeof ZMenuList>;

/**
 * All possible updates for a menu update, but not necessarily from the API.
 */
export const ZMenuUpdate = ZMenu.partial();
export type MenuUpdate = z.infer<typeof ZMenuUpdate>;

export const ZMenuPatch = ZMenuUpdate.omit({ id: true, images: true, lastModified: true }).partial();

export type MenuPatch = z.infer<typeof ZMenuPatch>;

export type MenuPhoto = z.infer<typeof ZMenuPhoto>;
