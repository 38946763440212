import { Menu, MenuList, MenuPatch, ZMenu, ZMenuList, ZMenuPhoto } from "@/src/common/menus";
import React, { ReactNode, useMemo } from "react";
import { Recipe, RecipePatch, RecipesList, ZRecipe, ZRecipePhoto, ZRecipesList } from "@/src/common/recipes";
import { User, ZUser } from "../common/users";
import { useUserContext } from "../components/auth_context";
import { z } from "zod";

type TokenProvider = () => Promise<string>;

/** React context pointing at the non-token taken API functions collection. */
const apiContext = React.createContext<APIContext>(null as unknown as APIContext);

/** Get access to the API interface. */
export function useAPIContext() {
	// TODO: Should this redirect to login if not logged in?
	return React.useContext(apiContext);
}

function createAPIContext(tokenProvider: TokenProvider) {
	return {
		createMenu: createMenu.bind(null, tokenProvider),
		createRecipe: createRecipe.bind(null, tokenProvider),
		deleteMenu: deleteMenu.bind(null, tokenProvider),
		deleteRecipe: deleteRecipe.bind(null, tokenProvider),
		getMenu: getMenu.bind(null, tokenProvider),
		getRecipe: getRecipe.bind(null, tokenProvider),
		getUser: getUser.bind(null, tokenProvider),
		listMenus: listMenus.bind(null, tokenProvider),
		listMenusByRecipe: listMenusByRecipe.bind(null, tokenProvider),
		listRecipes: listRecipes.bind(null, tokenProvider),
		updateMenu: updateMenu.bind(null, tokenProvider),
		updateMenuPhoto: updateMenuPhoto.bind(null, tokenProvider),
		updateRecipe: updateRecipe.bind(null, tokenProvider),
		updateRecipePhoto: updateRecipePhoto.bind(null, tokenProvider),
	};
}

/** Captures all API functions in their form without token first argument */
export type APIContext = ReturnType<typeof createAPIContext>;

/** Inserts the API context into the react hierarchy. */
export function APIContextWrapper(props: { children?: ReactNode }) {
	const { getToken } = useUserContext();

	const newAPIContext = useMemo(() => {
		const tokenProvider: TokenProvider = () => getToken();
		return createAPIContext(tokenProvider);
	}, [getToken]);

	return <apiContext.Provider value={newAPIContext}>{props.children}</apiContext.Provider>;
}

type NonEmptyString = Exclude<string, "">;

interface APIParams<Response extends z.AnyZodObject> {
	token: string;
	path: NonEmptyString;
	method: "GET" | "POST" | "DELETE" | "PATCH";
	data?: BodyInit;
	response_schema: Response;
	content_type?: string;
}

function jsonPayload(data: object): { data: BodyInit; content_type: "application/json" } {
	return {
		content_type: "application/json",
		data: JSON.stringify(data),
	};
}

function makePayload<Response extends z.AnyZodObject>(url: string, params: APIParams<Response>) {
	return new Request(url, {
		headers: new Headers({
			authorization: `Bearer ${params.token}`,
			// TODO: Accept header????
			...(params.content_type ? { "content-type": params.content_type } : {}),
		}),
		method: params.method,
		body: params.data,
	});
}

function is_acceptable(response: Response, acceptable_codes: number[]) {
	return response.ok || acceptable_codes.indexOf(response.status) >= 0;
}

async function startFallibleFetchInner<F extends z.AnyZodObject>(params: FallibleAPIParams<F>): Promise<Response> {
	const payload = makePayload(params.path, params);
	const apiResponse = await fetch(payload);

	if (!is_acceptable(apiResponse, params.acceptable_codes)) {
		const body = await apiResponse.text();
		throw new Error(
			`API call failed with status ${apiResponse.status.toString()} (${apiResponse.statusText})\nResponse body: ${body}\n`,
		);
	}

	return apiResponse;
}

interface FallibleAPIParams<Response extends z.AnyZodObject> extends APIParams<Response> {
	acceptable_codes: number[];
}

// TODO: Simplify interaction with APIContext...
async function startFallibleFetch<Response extends z.AnyZodObject>(
	params: FallibleAPIParams<Response>,
): Promise<z.infer<typeof params.response_schema> | null> {
	const apiResponse = await startFallibleFetchInner(params);

	if (apiResponse.ok) {
		const json: unknown = await apiResponse.json();
		return params.response_schema.parse(json);
	}
	return null;
}

// TODO: Simplify interaction with APIContext...
async function startFetch<Response extends z.AnyZodObject>(
	params: APIParams<Response>,
): Promise<z.infer<typeof params.response_schema>> {
	const apiResponse = await startFallibleFetchInner({
		...params,
		acceptable_codes: [],
	});

	const json: unknown = await apiResponse.json();
	return params.response_schema.parse(json);
}

async function createMenu(token: TokenProvider, initial_data: MenuPatch): Promise<Menu> {
	return startFetch({
		token: await token(),
		path: `/api/menus`,
		method: "POST",
		response_schema: ZMenu,
		...jsonPayload(initial_data),
	});
}

async function createRecipe(token: TokenProvider, initial_data: RecipePatch): Promise<Recipe> {
	return startFetch({
		token: await token(),
		path: `/api/recipes`,
		method: "POST",
		response_schema: ZRecipe,
		...jsonPayload(initial_data),
	});
}

async function getMenu(token: TokenProvider, id: string): Promise<Menu | null> {
	return startFallibleFetch({
		token: await token(),
		path: `/api/menus/${id}`,
		method: "GET",
		response_schema: ZMenu,
		acceptable_codes: [404],
		content_type: "application/json",
	});
}

async function getRecipe(token: TokenProvider, id: string): Promise<Recipe | null> {
	return await startFallibleFetch({
		token: await token(),
		path: `/api/recipes/${id}`,
		method: "GET",
		response_schema: ZRecipe,
		acceptable_codes: [404],
		content_type: "application/json",
	});
}

async function getUser(token: TokenProvider, id: string): Promise<User | null> {
	return await startFallibleFetch({
		token: await token(),
		path: `/api/users/${id}`,
		method: "GET",
		response_schema: ZUser,
		acceptable_codes: [404],
		content_type: "application/json",
	});
}

async function deleteRecipe(token: TokenProvider, id: string): Promise<void> {
	await startFetch({
		token: await token(),
		path: `/api/recipes/${id}`,
		method: "DELETE",
		response_schema: ZRecipe,
		content_type: "application/json",
	});
	return;
}

async function listRecipes(token: TokenProvider, query?: string): Promise<RecipesList> {
	const url = new URLSearchParams();
	if (query) {
		url.set("query", query);
	}

	return startFetch({
		token: await token(),
		path: "/api/recipes?" + url.toString(),
		method: "GET",
		response_schema: ZRecipesList,
		content_type: "application/json",
	});
}

async function updateRecipe(token: TokenProvider, id: string, recipe: RecipePatch): Promise<Recipe> {
	return await startFetch({
		token: await token(),
		path: `/api/recipes/${id}`,
		method: "PATCH",
		response_schema: ZRecipe,
		...jsonPayload(recipe),
	});
}

async function updateRecipePhoto(token: TokenProvider, id: string, image: string) {
	return await startFetch({
		token: await token(),
		path: `/api/recipes/${id}/photo`,
		method: "POST",
		response_schema: ZRecipePhoto,
		content_type: "image/png",
		data: image,
	});
}

async function updateMenu(token: TokenProvider, id: string, menu: MenuPatch): Promise<Menu> {
	return await startFetch({
		token: await token(),
		path: `/api/menus/${id}`,
		method: "PATCH",
		response_schema: ZMenu,
		...jsonPayload(menu),
	});
}

async function updateMenuPhoto(token: TokenProvider, id: string, image: string) {
	return await startFetch({
		token: await token(),
		path: `/api/menus/${id}/photo`,
		method: "POST",
		data: image,
		response_schema: ZMenuPhoto,
		content_type: "image/png",
	});
}

async function deleteMenu(token: TokenProvider, menu: string): Promise<void> {
	await startFetch({
		token: await token(),
		path: `/api/menus/${menu}`,
		method: "DELETE",
		response_schema: ZMenu,
		content_type: "application/json",
	});
	return;
}

async function listMenusByRecipe(_token: TokenProvider, _recipe: string): Promise<Menu[]> {
	console.log(`Warning: Unimplemented! ${await _token()}, ${_recipe}`);
	throw new Error("Function not implemented.");
}

async function listMenus(token: TokenProvider): Promise<MenuList> {
	return startFetch({
		token: await token(),
		method: "GET",
		path: `/api/menus`,
		response_schema: ZMenuList,
	});
}
