import { Auth } from "firebase/auth";
import React from "react";

function useSdk<Auth>(auth: React.Context<Auth | undefined>): Auth {
	const sdk = React.useContext(auth);

	if (!sdk) {
		throw new Error("SDK not found. useSdk must be called from within a provider");
	}

	return sdk;
}

export const AuthSdkContext = React.createContext<Auth | undefined>(undefined);
export const useAuth = () => useSdk<Auth>(AuthSdkContext);
