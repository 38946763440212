import { z } from "zod";

export const ZUser = z.object({
	id: z.string(),
	email: z.string().nullable(),
	name: z.string().nullable(),
	photo: z.string().nullable(),
});

export type User = z.infer<typeof ZUser>;
