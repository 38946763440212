import {
	GoogleAuthProvider,
	Unsubscribe,
	User,
	browserPopupRedirectResolver,
	signInWithPopup,
	signOut,
} from "firebase/auth";
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "../client/auth";

export interface UserContext {
	user: User | null;
	ready: boolean;
	signInFBUser: () => Promise<void>;
	signOutFBUser: () => Promise<void>;
	getToken: () => Promise<string>;
}

const userContext = createContext<UserContext>(null as unknown as UserContext);

export function useUserContext() {
	return useContext(userContext);
}

interface UserContextWrapperProps {
	children: ReactNode;
}

export function UserContextWrapper(props: UserContextWrapperProps) {
	const auth = useAuth();
	const [user, setUser] = useState<User | null>(null);
	const [ready, setReady] = useState(false);
	const [loaderRunning, setLoaderRunning] = useState(false);

	const signInFBUser = useCallback(async () => {
		const result = await signInWithPopup(auth, new GoogleAuthProvider(), browserPopupRedirectResolver);
		setUser(result.user);
	}, [auth]);

	const signOutFBUser = useCallback(async () => {
		await signOut(auth);
		setUser(null);
	}, [auth, setUser]);

	const getToken = useCallback(async () => {
		const token = await user?.getIdToken();
		if (!token) {
			throw new Error("Unable to fetch user token");
		}
		return token;
	}, [user]);

	useEffect(() => {
		if (!loaderRunning) {
			setLoaderRunning(true);
			const unsub: Unsubscribe = auth.onAuthStateChanged((user) => {
				if (!ready) {
					setReady(true);
				}

				if (user) {
					setUser(user);
				}
				unsub();
			});
		}
	}, [ready, loaderRunning, auth]);

	const value: UserContext = useMemo(
		() => ({
			ready,
			user,
			signInFBUser,
			signOutFBUser,
			getToken,
		}),
		[getToken, signOutFBUser, signInFBUser, user, ready],
	);

	return <userContext.Provider value={value}>{props.children}</userContext.Provider>;
}
