import { z } from "zod";

export const ZRecipePhoto = z.object({
	normal: z.string(),
	thumbnail: z.string(),
});

export const ZRecipe = z.object({
	name: z.string(),
	id: z.string(),
	tags: z.array(z.string()),
	lastModified: z.coerce.date(),
	lastModifiedBy: z.nullable(z.string()),
	instructions: z.string(),
	images: z.nullable(ZRecipePhoto),
});

/**
 * All possible updates for a recipe update, but not necessarily from the API.
 */
export const ZRecipeUpdate = ZRecipe.partial();
export type RecipeUpdate = z.infer<typeof ZRecipeUpdate>;

export const ZRecipePatch = ZRecipeUpdate.omit({ id: true, images: true, lastModified: true }).partial();

export type RecipePatch = z.infer<typeof ZRecipePatch>;

export type Recipe = z.infer<typeof ZRecipe>;

export const ZRecipesList = z.object({
	recipes: z.array(ZRecipe),
});

export type RecipesList = z.infer<typeof ZRecipesList>;

export type RecipePhoto = z.infer<typeof ZRecipePhoto>;
