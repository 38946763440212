"use client";

import "@/styles/globals.sass";
import { ReactNode, Suspense } from "react";
import { APIContextWrapper } from "../client/api";
import { LoadingPage } from "@/src/components/loading_page";
import { useUserContext } from "@/src/components/auth_context";

function LoginOptions() {
	const auth = useUserContext();

	return (
		<div className="">
			<h1 className="title is-1" role="heading">
				Sign in to continue...
			</h1>
			<button role="button" className="button is-success" onClick={() => void auth.signInFBUser()}>
				Log in
			</button>
		</div>
	);
}

interface LayoutProps {
	children: ReactNode;
}

export function ContentLayout({ children }: LayoutProps) {
	const user_context = useUserContext();

	if (!user_context.user && user_context.ready) {
		return <LoginOptions />;
	} else if (!user_context.ready) {
		return <LoadingPage />;
	}

	return (
		<APIContextWrapper>
			<Suspense fallback={<LoadingPage />}>{children}</Suspense>
		</APIContextWrapper>
	);
}
