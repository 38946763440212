"use client";

import { JSX, Suspense } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";
import React from "react";
import classnames from "classnames";
import { useState } from "react";
import { useUserContext } from "@/src/components/auth_context";

function UserArea(): JSX.Element {
	const { user, ready, signInFBUser } = useUserContext();

	if (!ready) {
		return (
			<div className="navbar-item">
				<button className="button is-loading"></button>
			</div>
		);
	} else if (user) {
		const photo = user.providerData[0]?.photoURL;
		return (
			<Link className="navbar-item" href="/profile">
				{photo && (
					<picture className="image is-24x24 is-inline-block" style={{ verticalAlign: "middle" }}>
						<img alt={user.displayName || user.email || "User icon"} className="is-rounded" src={photo} />
					</picture>
				)}
				&nbsp;
				{user.displayName || user.email}
			</Link>
		);
	}
	return (
		<div className="navbar-item">
			<button className="button is-success" onClick={() => void signInFBUser()}>
				Log in
			</button>
		</div>
	);
}

function SearchBar() {
	const params = useSearchParams();
	const filters = params.getAll("filters");
	const [searchPrompt, setSearchPrompt] = useState(filters.join(" "));
	const router = useRouter();

	function submit(e: React.FormEvent) {
		e.preventDefault();
		router.push(
			`/recipes?${new URLSearchParams({
				filters: searchPrompt,
			})}`,
		);
	}

	return (
		<form onSubmit={submit}>
			<div className="field has-addons">
				<div className="control">
					<input
						className="input"
						type="text"
						placeholder="🔎 Search"
						value={searchPrompt}
						onChange={(e) => {
							setSearchPrompt(e.target.value);
						}}
					/>
				</div>
				<div className="control">
					<button className="button is-info">🔎 Search</button>
				</div>
			</div>
		</form>
	);
}

interface HamburgerProps {
	menuExpanded: boolean;
	setMenuExpanded: (expanded: boolean) => void;
}

function Hamburger({ menuExpanded, setMenuExpanded }: HamburgerProps) {
	return (
		<a
			role="button"
			className={classnames({
				"navbar-burger": true,
				"is-active": menuExpanded,
			})}
			// TODO: This should be a bulma thing? How do I fix it?
			style={{ color: "white" }}
			aria-label="menu"
			aria-expanded="false"
			onClick={() => {
				setMenuExpanded(!menuExpanded);
			}}
		>
			<span aria-hidden="true"></span>
			<span aria-hidden="true"></span>
			<span aria-hidden="true"></span>
		</a>
	);
}

export default function Nav() {
	const [menuExpanded, setMenuExpanded] = useState(false);

	return (
		<>
			<nav className="navbar is-link" role="navigation" aria-label="main navigation">
				<div className="navbar-brand">
					<Link href="/" className="navbar-item">
						⏲️ Our Cookbook
					</Link>
					<Hamburger menuExpanded={menuExpanded} setMenuExpanded={setMenuExpanded} />
				</div>

				<div
					className={classnames({
						"navbar-menu": true,
						"is-active": menuExpanded,
					})}
				>
					<div className="navbar-start">
						<Link href="/recipes/" className="navbar-item">
							🍳 Recipes
						</Link>
						<Link href="/menus/" className="navbar-item">
							📜 Menus
						</Link>
					</div>

					<div className="navbar-end">
						<Suspense>
							<UserArea />
						</Suspense>
						<div className="navbar-item">
							<Suspense>
								<SearchBar />
							</Suspense>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
}
