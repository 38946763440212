import {
	browserSessionPersistence,
	connectAuthEmulator,
	indexedDBLocalPersistence,
	initializeAuth,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
	apiKey: "AIzaSyA1XSL5UwkldEaf-qxuBIR6oCBuUFVROCc",
	authDomain: "emarcotte-com.firebaseapp.com",
	projectId: "emarcotte-com",
	storageBucket: "emarcotte-com.appspot.com",
	messagingSenderId: "186074162970",
	appId: "1:186074162970:web:9633884fa5f741869e2ea3",
	measurementId: "G-VZB9Q89S7W",
};

export const firebaseEmulation = {
	emulatorHost: "localhost",
	enabled: process.env.NEXT_PUBLIC_EMULATOR == "true",
};

export function initClientSideFirebase(emulator?: boolean) {
	const app = initializeApp(firebaseConfig, "recipes.emarcotte.com");

	const browserSession = typeof window == "undefined" || firebaseEmulation.enabled || emulator;

	// https://makerkit.dev/blog/tutorials/ultimate-guide-authentication-firebase-nextjs
	const auth = initializeAuth(app, {
		persistence: browserSession ? browserSessionPersistence : indexedDBLocalPersistence,
	});

	if ((firebaseEmulation.enabled || emulator) && !("emulator" in auth.config)) {
		connectAuthEmulator(auth, "http://localhost:9099");
	}

	return { app, auth };
}
