"use client";

import { Context, PropsWithChildren, ReactNode, createContext, useContext, useMemo } from "react";
import { AuthSdkContext } from "@/src/client/auth";
import { ContentLayout } from "@/src/components/layout";
import { FirebaseApp } from "firebase/app";
import Nav from "@/src/components/nav";
import { UserContextWrapper } from "@/src/components/auth_context";
import { initClientSideFirebase } from "@/src/client/config";

interface RootLayoutProps {
	children: ReactNode;
}

interface FirebaseAppProviderProps {
	firebaseApp: FirebaseApp;
}

export function useFirebaseApp() {
	const firebaseApp = useContext(FirebaseAppContext);
	if (!firebaseApp) {
		throw new Error("Firebase app context provider isn't set up properly!");
	}

	return firebaseApp;
}

const FirebaseAppContext = createContext<FirebaseApp | undefined>(undefined);

function FirebaseAppProvider(props: PropsWithChildren<FirebaseAppProviderProps>) {
	return <FirebaseAppContext.Provider value={props.firebaseApp}>{props.children}</FirebaseAppContext.Provider>;
}

function getSdkProvider<Auth>(SdkContext: Context<Auth>) {
	return function SdkProvider(props: PropsWithChildren<{ sdk: Auth }>) {
		return <SdkContext.Provider value={props.sdk} {...props} />;
	};
}

const AuthProvider = getSdkProvider(AuthSdkContext);

function FirebaseWrapper({ children }: { children: ReactNode }) {
	const { app, auth } = useMemo(() => {
		return initClientSideFirebase();
	}, []);

	return (
		<FirebaseAppProvider firebaseApp={app}>
			<AuthProvider sdk={auth}>
				<UserContextWrapper>{children}</UserContextWrapper>
			</AuthProvider>
		</FirebaseAppProvider>
	);
}

// Factored out only to make it easier to test in render(). I dont know how to render root level html element in tests.
export function Layout({ children }: RootLayoutProps) {
	return (
		<>
			<head>
				<title>Our cookbook</title>
				<meta name="description" content="recipe site" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="icon" href="/favicon.ico" />
			</head>
			<body>
				<FirebaseWrapper>
					<Nav />
					<section className="section">
						<div className="container">
							<ContentLayout>{children}</ContentLayout>
						</div>
					</section>
				</FirebaseWrapper>
			</body>
		</>
	);
}

export default function RootLayout({ children }: RootLayoutProps) {
	return (
		<html lang="en">
			<Layout>{children}</Layout>
		</html>
	);
}
